html {
  overscroll-behavior-x: none;
} 
body {
  overscroll-behavior-x: none;
}

/* ------- NAVIGATION ------- */
.navContainer {
  background-color: #333;
  height:9vh; /* other 81vh is for right panel*/
  align-self: flex-start;
  width:100%;
}

.littleLogo {
  display: flex;
    justify-content: center;
    align-items: center;
  float: left;
  border: 2;
  border-radius: 10;
  width: 80;
  padding-left: 10px;
  padding-top: 10px;
  clear: left;
}

.littleLogo img {
  width: 44px;
  margin-right: 16px;
}

.littleBrand {
  display: inline-block;
  font-size:32px;
  font-weight: bold;
  color: #fff;
}

.navButton {
  font-size:24px;
  font-weight: bold;
  float:right;
  text-align:center;
  background-color: white;
  border: 2;
  border-radius: 16px;
  margin-left: 20px;
  margin-right: 25px;
  margin-top: 13px;
  padding-left: 20px;
  padding-right:20px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.navButton:hover{
  opacity:0.8;
  cursor:pointer;
}
/* ------- NAVIGATION END ------- */


/* ------- PROCESS ------- */
input[type="file"] {
  display: none;
}

.section {
  margin: auto;
  text-align: center;
  overflow-x: auto;
  align-items: center;
}

.below-process {
  margin-bottom: auto;
  height: calc(100% - 380px);
}

.processContainer {
  height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: hidden;
}

.leftContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.centerContainer {
  height: 100%;
  width: calc(100% - 600px);
  overflow: hidden;
}

.processStart {
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  color: #606F7E
}

.bloxTop{
  margin: auto;
  background-color: #e1e6ff;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  padding: 5px;
  position: relative
}

.bloxImageTitle {
  display: block;
  text-overflow: ellipsis;
  font-weight: 500;
  max-width: 150px;
  white-space: nowrap;
  max-height: 1.2em;
  overflow: hidden;
}

.bloxImage {
  margin: auto;
  width: 120px;
}

.popover-menu-item {
  min-width: 300px;
}

.bloxTop:hover {
  box-shadow:0 0 10px rgb(87, 209, 240);
  cursor: grab;
}

.bloxTop.noDrag:hover{
  cursor: auto;
}

.bloxTop.selected {
  box-shadow:0 0 10px rgb(87, 209, 240);
}

.bloxBottom {
  margin: auto;
  position: relative;
  background-color: #e1e6ff;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  cursor: context-menu;
}

.bloxMenuIcon {
  position: absolute;
  background-color: #D3D8DE;
  top: 0px;
  right: 0px;
  padding: 2px 8px 3px 8px;
  display: flex;
  flex-direction: row;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  line-height: 10px;
}

.bloxMenuIcon .bp5-icon {
  cursor: pointer;
  transition: transform 0.2s;
}

.bloxMenuIcon .bp5-icon:hover {
  transform: scale(1.1); 
}

.bloxExport {
  margin: 0px auto 15px auto;
  position: relative;
  text-align: center;
}
/* ------- PROCESS END ------- */

/* ------- SVG ------- */
.bloxSVG {
  position: absolute;
  width: 120px;
  bottom: 10px;
  left: 15px;
}

.thumbnailSVG {
  width: 46px;
}

.bloxSVGPreview {
  width:240px;
}
/* ------- SVG END ------- */


/* ------- PLUS ------- */
.plusButton {
  width: 10%;
}

.plusButton:hover {
  opacity:0.8;
}

.betweenBloxesContainer {
  display: flex;
  align-items: center;
  margin: 10px;
}

.betweenBloxesPlus {
  margin: auto;
  width: 60px;
  height: 60px;
}

.betweenBloxesArrowContainer {
  height: 150px;
  display: flex;
  align-items: center;
}

.betweenAddContainer {
  align-items: center;
}

.hidden {
  display: none;
  height: 30px;
}

.betweenAddContainer:hover .show-on-hover {
  display: block;
}

/* ------- PLUS END ------- */

/*  -------LAYOUT ------- */
.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
}
/* ------- LAYOUT END ------- */

/* ------- BOTTOM PANEL ------- */

.library-callout {
  margin: auto;
  width: 700px !important;
}

.error-callout {
  margin: auto;
  width: 700px !important;
}

.editor-footer {
  background-color: #e1e6ff;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  flex-shrink: 0;
  position: relative;
}

 .blox-library-container {
  overflow-x: auto;
  height: 100%
}

.blox-library-tabs {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
}

.blox-library-tabs.open {
  padding: 10px 10px 0px 10px;
}

.library-button {
  position: absolute;
  margin-left: 10px;
}

.library-blox-button {
  position: absolute;
  margin-left: 10px;
}

.library-content {
  height: 0px;
  transition: height .2s;
}

.library-content.open{
  height: 135px;
  transition: height .2s;
}

.library-content.closed{
  height: 0px;
  overflow: hidden;
  transition: height .2s;
}

/* LIBRARY */
.blox-library {
  background-color: #ffffff;
  height: 100px;
  width: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 2px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.blox-library:hover {
  cursor: grab;
  box-shadow:0 0 10px rgb(87, 209, 240);
}

.library-label {
  width: calc(100% - 10px);
  position: absolute;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: small;
}

.library-label.top {
  width: calc(100% - 20px);
  left: 3px;
  top: 3px;
}

.library-label.bottom {
  bottom: 3px;
  width: 100%;
  text-align: center;
}

.library-image-icon-button {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.blox-library-image {
  margin: auto auto 5px auto;
  width: 80%;
}

.module-library-image {
  margin: auto;
  width: 60% !important;
}

/* LIBRARY END */

/* ------- BOTTOM PANEL END ------- */

/* ------- RIGHT PANEL ------- */
.right-panel{
  flex-shrink: 0;
  height: 100vh;
  width: 300px;
  background-color: #e1e6ff;
  border-left: #CED9E0 2px solid;
  margin-left: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.rightPanelTitle {
  font-size: large;
  margin: auto;
}

.rightPanelImage {
  width: 50%;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  margin-top: 8px;
}

.rightPanelCommentsContainer {
  height: 200px !important;
}

.rightPanelComments {
  height: 183px !important;
}

.inputMenu {
  min-width: 0px !important;
}

.inputMenuPopover {
  margin-top: 0px !important;
}

.colorPreview {
	width: 30px;
	height: 30px;
	margin: 3px;
	border: 1px solid #DDD;
	float: left;
	transition: all 0.2s;
}

.colorPreview:hover {
	cursor: pointer;
	border-color: #BBB;
	transform: scale(1.15);
	border-radius: 3px;
}

textarea {
  resize: none;
}

/* TODO: better CSS */

.bp5-icon-lab-test svg {
  color: #5c7080;
}

.bp5-tag-input {
  padding-top: 1px !important;
}

.righPanelLabel {
  position: relative;
}

.removeButton {
  position: absolute;
  right:0;
  top:-2px;
  z-index: 1000;
}

.blox-tab-group {
  display: flex;
}

.blox-tab-group .simulate-tab {
  transition: all 0.3s ease-in-out;
}

.blox-tab-group .simulate-tab-hidden {
  flex-grow: 0 !important;
  opacity: 0;
  visibility: hidden;
  min-width: 0;
  width: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

/* ------- RIGHT PANEL END ------- */

/* Text Styles */

.warning-text {
  font-size: small;
  font-weight: 400;
  color: red;
}


/* ------- SEARCH ------- */

.searchContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.searchBarContainer {
  width: 100%;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 8px;

}

.searchBarText {
  padding: 3px;
  width: 75%;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align:center;
  font-size: 17px;
}

/* Landing Page */

.profile-description {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    width: calc(100% - 20px);
    background: #FFFFFF;
    border-radius: 4px;
    flex: none;
    flex-grow: 1;
}

/* Dialog stuff */

.jump-to-button {
    margin: 5px 10px 0px auto;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;  /* Smooth transition for opacity change */
  }

  .jump-to-button:hover {
      opacity: 1;  /* Full opacity on hover */
  }
  
